import React from 'react';
import Main from "./Component/Main"
import './App.css'

function App() {
  return (
  <div className='container'>
      <Main />
    </div>
  )
}

export default App
