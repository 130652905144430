import React from 'react'
import './Main.style.css'
import logo from '../assets/img/logo-m-500-300x97.png'
import logoSg from '../assets/img/logo.png'
import { Typography } from '@mui/material';

const Main = () => {
  return (
    <div className='container'>
        <div>
            <img src={logo} />
        </div>
        <div className='row'>
            <div><img src={logoSg} width='70' /></div>
            <div><Typography className='title' align='justify'>ServerGlobal</Typography></div>
        </div>
    </div>
  )
}

export default Main